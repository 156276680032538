import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Navbar, Nav, Container } from 'react-bootstrap';

const NavbarComponent = () => {
    const { isAuthenticated, logout } = useAuth();

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="py-3" style={{ backgroundColor: '#242b33' }}>
            <Container fluid style={{ padding: '0 40px' }}>
                <Navbar.Brand href="/">
                    <img
                        src={`${process.env.PUBLIC_URL}/title-logo-reverse.svg`}
                        width="100"
                        height="30"
                        className="d-inline-block align-top"
                        alt="App Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="me-auto">
                        {isAuthenticated && (
                            <>
                                <Nav.Link as={NavLink} to="/">Analytics</Nav.Link>
                                <Nav.Link as={NavLink} to="/demo-keys">Create Demo Keys</Nav.Link>
                                <Nav.Link as={NavLink} to="/bundle-keys">Create Bundle Keys</Nav.Link>
                                <Nav.Link as={NavLink} to="/pending-approvals">Pending Approvals</Nav.Link>
                                <Nav.Link as={NavLink} to="/settings">Settings</Nav.Link>
                            </>
                        )}
                    </Nav>
                    <Nav className="ms-auto">
                        {isAuthenticated ? (
                            <Nav.Link onClick={logout}>Logout</Nav.Link>
                        ) : (
                            <Nav.Link as={NavLink} to="/login">Login</Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarComponent;
