import React, { useState, useEffect } from 'react';
import { fetchAllApprovals, approveRequest, denyRequest, deleteRequest } from '../api/packageApi';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

const PendingApprovals = () => {
    const [approvals, setApprovals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedApprovalId, setSelectedApprovalId] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [csvDownloadUrl, setCsvDownloadUrl] = useState("");
    const [actionType, setActionType] = useState(""); // New state for action type
    const [actionCompleted, setActionCompleted] = useState(false);

    useEffect(() => {
        loadPendingApprovals();
    }, []);

    const loadPendingApprovals = async () => {
        setLoading(true);
        try {
            const data = await fetchAllApprovals();
            setApprovals(data);
        } catch (error) {
            console.error("Failed to fetch pending approvals", error);
        } finally {
            setLoading(false);
        }
    };

    const handleApproveClick = (approvalId) => {
        setSelectedApprovalId(approvalId);
        setActionType("approve"); // Set action type explicitly
        setShowModal(true);
        setModalMessage("Are you sure you want to approve this request?");
    };

    const handleDenyClick = (approvalId) => {
        setSelectedApprovalId(approvalId);
        setActionType("deny"); // Set action type explicitly
        setShowModal(true);
        setModalMessage("Are you sure you want to disapprove this request?");
    };

    const handleDeleteClick = async (approvalId) => {
        if (window.confirm("Are you sure you want to delete this request?")) {
            try {
                await deleteRequest(approvalId);
                loadPendingApprovals(); // Refresh list after deletion
            } catch (error) {
                console.error("Failed to delete request", error);
            }
        }
    };

    const handleDownloadCsv = () => {
    if (csvDownloadUrl) {
        const link = document.createElement('a');
        link.href = csvDownloadUrl;  // Ensure this is the full URL with origin
        link.download = `${selectedApprovalId}.csv`;  // Corrects to download file with .csv extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};


const handleModalConfirm = async () => {
    setModalLoading(true);
    try {
        if (actionType === "approve") {
            await approveRequest(selectedApprovalId);
            setModalMessage("Request approved successfully. Generating CSV and sending email...");
            setCsvDownloadUrl(`/api/download-csv/${selectedApprovalId}`);
        } else if (actionType === "deny") {
            await denyRequest(selectedApprovalId);
            setModalMessage("Request disapproved successfully.");
            setCsvDownloadUrl("");
        }

        setActionCompleted(true); // Mark action as completed
        loadPendingApprovals(); // Refresh list

    } catch (error) {
        console.error("Failed to process request", error);
        setModalMessage("Error processing request.");
    } finally {
        setModalLoading(false);
    }
};

const handleModalClose = () => {
    setShowModal(false);
    setModalLoading(false);
    setSelectedApprovalId(null);
    setCsvDownloadUrl("");
    setActionType("");
    setModalMessage("");
    setActionCompleted(false); // Reset action completed state
};



    return (
        <div className="container-fluid min-vh-100" style={{ backgroundColor: '#242b33', color: '#fff', padding: '40px' }}>
            <h1 className="mb-4">Pending Approvals</h1>
            {loading ? (
                <Spinner animation="border" variant="light" />
            ) : approvals.length > 0 ? (
                <ul className="list-group">
                    {approvals.map(approval => (
                        <li key={approval.approval_id} className="list-group-item bg-dark text-white d-flex justify-content-between align-items-center">
                            <div>
                                Bundle ID: {approval.bundle_id}, Vendor: {approval.vendor}, 
                                Key Amount: {approval.key_amount}, Trial Months: {approval.trial_months}, 
                                Email: {approval.email}, Status: {approval.status}
                            </div>
                            <div>
                                {approval.status === "Pending" && (
                                    <>
                                        <Button variant="success" onClick={() => handleApproveClick(approval.approval_id)}>Approve</Button>
                                        <Button variant="warning" className="ml-2" onClick={() => handleDenyClick(approval.approval_id)}>Deny</Button>
                                    </>
                                )}
                                <Button variant="danger" className="ml-2" onClick={() => handleDeleteClick(approval.approval_id)}>Delete</Button>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No approval requests available.</p>
            )}

            {/* Modal for Approve/Disapprove Confirmation */}
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Approval Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalLoading ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="primary" />
                            <p>{modalMessage}</p>
                        </div>
                    ) : (
                        <p>{modalMessage}</p>
                    )}
                </Modal.Body>
<Modal.Footer>
    {actionCompleted ? (
        <Button variant="secondary" onClick={handleModalClose}>Close</Button>
    ) : (
        <>
            <Button variant="secondary" onClick={handleModalClose}>Cancel</Button>
            <Button variant="primary" onClick={handleModalConfirm} disabled={modalLoading}>
                {modalLoading ? <Spinner animation="border" size="sm" /> : "Confirm"}
            </Button>
        </>
    )}
</Modal.Footer>


            </Modal>
        </div>
    );
};

export default PendingApprovals;
