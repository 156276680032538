const BASE_URL = 'http://54.214.4.206:5004/api'; // Use the same base URL


// Fetch CC Emails
export const fetchCcEmails = async () => {
    const response = await fetch(`${BASE_URL}/settings/cc-emails`);
    if (!response.ok) throw new Error('Failed to fetch CC emails');
    return response.json();  // Should return an array of CC emails
};

export const addCcEmail = async (email) => {
    const response = await fetch(`${BASE_URL}/settings/cc-emails`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cc_email: email }),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to add CC email');
    }

    return response.json();
};



export const removeCcEmail = async (email) => {
    const response = await fetch(`${BASE_URL}/settings/cc-emails/${encodeURIComponent(email)}`, {
        method: 'DELETE',
    });
    if (!response.ok) throw new Error('Failed to remove CC email');
    return response.json();
};




export const fetchEmailContent = async () => {
    const response = await fetch(`${BASE_URL}/settings/email-content`);
    if (!response.ok) throw new Error('Failed to fetch email content');
    return response.json();
};

export const updateEmailContent = async (subject, body) => {
    const response = await fetch(`${BASE_URL}/settings/email-content`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subject, body }),
    });
    if (!response.ok) throw new Error('Failed to update email content');
    return response.json();
};




// Document Management API
export const fetchDocs = async () => {
    const response = await fetch(`${BASE_URL}/settings/docs`);
    if (!response.ok) throw new Error('Failed to fetch documents');
    return response.json();
};

export const uploadDoc = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${BASE_URL}/settings/upload-document`, {
        method: 'POST',
        body: formData,
    });
    if (!response.ok) throw new Error('Failed to upload document');
    return response.json();
};


export const deleteDoc = async (docName) => {
    const response = await fetch(`${BASE_URL}/settings/docs/${docName}`, {
        method: 'DELETE',
    });
    if (!response.ok) throw new Error('Failed to delete document');
    return response.json();
};

// Fetch Users with Roles
export const fetchUsers = async () => {
    const response = await fetch(`${BASE_URL}/auth/users`);
    if (!response.ok) throw new Error('Failed to fetch users');
    const data = await response.json();
    return Array.isArray(data.users) ? data.users : [];
};

// Add User with Role (defaults to "User")
export const addUser = async (email, password) => {
    const response = await fetch(`${BASE_URL}/auth/add-user`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
    });
    if (!response.ok) throw new Error('Failed to add user');
    return response.json();
};

// Set User Role and Permissions
export const setUserRoleAndVisibility = async (email, role, visibility) => {
    const response = await fetch(`${BASE_URL}/auth/set-role-visibility`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, role, visibility }),
    });
    if (!response.ok) throw new Error('Failed to update user role and visibility');
    return response.json();
};

// Delete User
export const deleteUser = async (email) => {
    const response = await fetch(`${BASE_URL}/auth/delete-user`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
    });
    if (!response.ok) throw new Error('Failed to delete user');
    return response.json();
};

// Login function for authenticating users
export const login = async (email, password) => {
    const response = await fetch(`${BASE_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
    });
    if (!response.ok) throw new Error('Failed to login');
    return response.json();
};
