import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaChevronDown, FaChevronUp, FaTrash } from 'react-icons/fa';

const StripeSubscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [csvFiles, setCsvFiles] = useState([]);
    const [retailer, setRetailer] = useState('all');
    const [statusFilter, setStatusFilter] = useState('all');
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [eventSource, setEventSource] = useState(null);
    const [isStopped, setIsStopped] = useState(false);
    const [collapsed, setCollapsed] = useState({
        redeemed: true,
        status: true,
        timeframe: true,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const graphRefs = {
        redeemed: useRef(null),
        status: useRef(null),
        timeframe: useRef(null)
    };

const calculateAggregation = () => {
    const aggregation = {
        redeemed: { pending: 0, active: 0 },
        status: { trialing: 0, paid: 0 },
        timeframe: { monthly: 0, yearly: 0 }
    };

    subscriptions.forEach(subscription => {
        // Check for redeemed status; count as 'active' if undefined
        if (subscription.redeemed === 'pending') {
            aggregation.redeemed.pending++;
        } else {
            aggregation.redeemed.active++; // Default to active if undefined
        }

        // Count based on the trialing/paid status
        if (subscription.status === 'trialing') {
            aggregation.status.trialing++;
        } else if (subscription.status === 'active') {
            aggregation.status.paid++;
        }

        // Count based on the billing timeframe
        if (subscription.current_billing_timeframe === '12') {
            aggregation.timeframe.yearly++;
        } else if (subscription.current_billing_timeframe === '1') {
            aggregation.timeframe.monthly++;
        }
    });

    // Cross-check to ensure the sums align across redeemed and status counts
    const totalRedeemedCount = aggregation.redeemed.pending + aggregation.redeemed.active;
    const totalStatusCount = aggregation.status.trialing + aggregation.status.paid;

    if (totalRedeemedCount !== totalStatusCount) {
        console.warn("Count discrepancy found between redeemed and status categories.");
    }

    return aggregation;
};



    const aggregation = calculateAggregation();

useEffect(() => {
    const fetchCsvFiles = async () => {
        try {
            const response = await fetch('http://54.214.4.206:5004/csv/csv-files');
            if (!response.ok) throw new Error('Failed to fetch CSV files');
            const data = await response.json();
            if (data.csv_files) setCsvFiles(data.csv_files);
        } catch (error) {
            console.error('Error fetching CSV files:', error);
        }
    };
    fetchCsvFiles();
}, []);

    const handleFetchClick = () => {
        setSubscriptions([]);
        setIsStopped(false);
        fetchSubscriptions();
    };

const handleDeleteFile = async (fileName) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${fileName}?`);
    if (!confirmDelete) return;

    try {
        const response = await fetch(`http://54.214.4.206:5004/csv/delete-csv?file_name=${fileName}`, {
            method: 'DELETE'
        });
        if (!response.ok) throw new Error('Failed to delete file');
        
        alert(`File '${fileName}' deleted successfully`);
        setCsvFiles((prevFiles) => prevFiles.filter(file => file !== fileName));
    } catch (error) {
        console.error('Error deleting file:', error);
        alert('Error deleting file');
    }
};

const fetchSubscriptions = () => {
    setLoading(true);
    setIsStopped(false);
    const es = new EventSource(`http://54.214.4.206:5004/stripe/subscriptions?source=${retailer}`);

    es.onmessage = (event) => {
        try {
            const batch = JSON.parse(event.data);
            const subscriptionBatch = Array.isArray(batch) ? batch : [batch];
            setSubscriptions((prevSubs) => [...prevSubs, ...subscriptionBatch]);
        } catch (error) {
            console.error('Error processing subscriptions:', error);
        }
    };

    es.onerror = (error) => {
        console.error('Error fetching subscriptions:', error);
        es.close();
        setLoading(false);
        setEventSource(null);
    };

    es.onclose = () => {
        console.log('Fetching subscriptions completed');
        setLoading(false);
        setEventSource(null);
    };

    setEventSource(es);
};


    const handleStopClick = () => {
        if (eventSource) {
            eventSource.close();
            setEventSource(null);
            setIsStopped(true);
            console.log('Stream stopped');
        }
    };

    const handleResumeClick = () => {
        if (!eventSource && isStopped) {
            fetchSubscriptions();
            setIsStopped(false);
        }
    };

    const createChart = (chartRef, data, title) => {
        const margin = { top: 40, right: 20, bottom: 50, left: 50 };
        const width = 400 - margin.left - margin.right;
        const height = 300 - margin.top - margin.bottom;

        d3.select(chartRef).selectAll('*').remove();

        const svg = d3.select(chartRef)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const x = d3.scaleBand()
            .domain(data.map(d => d.label))
            .range([0, width])
            .padding(0.2);

        const maxValue = d3.max(data, d => d.value) || 1;
        const y = d3.scaleLinear()
            .domain([0, maxValue])
            .range([height, 0]);

        svg.append('text')
            .attr('x', width / 2)
            .attr('y', -10)
            .attr('text-anchor', 'middle')
            .attr('font-size', '16px')
            .attr('fill', '#ffffff')
            .text(title);

        svg.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(x))
            .selectAll('text')
            .attr('dy', '1em')
            .attr('font-size', '12px')
            .attr('fill', '#ffffff');

        svg.append('g')
            .call(d3.axisLeft(y).ticks(4))
            .attr('font-size', '12px')
            .attr('fill', '#ffffff');

        svg.selectAll('.bar')
            .data(data)
            .enter()
            .append('rect')
            .attr('x', d => x(d.label))
            .attr('y', d => y(d.value))
            .attr('width', x.bandwidth())
            .attr('height', d => height - y(d.value))
            .attr('fill', d => d.label.includes('Pending') || d.label.includes('Trialing') || d.label.includes('Monthly') ? '#ff6384' : '#36a2eb');
    };

    useEffect(() => {
        if (!collapsed.redeemed) {
            createChart(graphRefs.redeemed.current, [
                { label: 'Pending', value: aggregation.redeemed.pending },
                { label: 'Active', value: aggregation.redeemed.active },
            ], 'Pending vs Redeemed');
        }
        if (!collapsed.status) {
            createChart(graphRefs.status.current, [
                { label: 'Trialing', value: aggregation.status.trialing },
                { label: 'Paid', value: aggregation.status.paid },
            ], 'Trialing vs Paid');
        }
        if (!collapsed.timeframe) {
            createChart(graphRefs.timeframe.current, [
                { label: 'Monthly', value: aggregation.timeframe.monthly },
                { label: 'Yearly', value: aggregation.timeframe.yearly },
            ], 'Monthly vs Yearly');
        }
    }, [subscriptions, collapsed]);

    const toggleCollapse = (key) => {
        setCollapsed((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const handleFileSelect = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!selectedFile) return alert("Please select a file to upload.");

        const formData = new FormData();
        formData.append("file", selectedFile);

        setUploading(true);

        try {
            const response = await fetch('http://54.214.4.206:5004/csv/upload-csv', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText);
            }

            alert('CSV file uploaded successfully!');
            setCsvFiles((prevFiles) => [...prevFiles, selectedFile.name.replace('.csv', '')]);
        } catch (error) {
            console.error('Error uploading CSV:', error);
            alert(`Error uploading CSV: ${error.message}`);
        } finally {
            setUploading(false);
            setSelectedFile(null);
        }
    };

    const filteredSubscriptions = subscriptions.filter((sub) =>
        statusFilter === 'all' || sub.status === statusFilter || sub.redeemed === statusFilter
    );

const totalPages = filteredSubscriptions.length > 0 ? Math.ceil(filteredSubscriptions.length / rowsPerPage) : 1;
const startIdx = (currentPage - 1) * rowsPerPage;
const paginatedSubscriptions = filteredSubscriptions.slice(startIdx, startIdx + rowsPerPage);


    return (
        <div className="container-fluid min-vh-100" style={{ backgroundColor: '#242b33', color: '#fff', padding: '40px' }}>
            <h1 className="mb-4">Analytics Dashboard</h1>

<div className="row mb-4">
    {/* Pick a Bundle Section */}
    <div className="col-md-3" style={{ paddingRight: '20px' }}>
        <label htmlFor="retailer">Pick a Bundle:</label>
        <div className="d-flex align-items-center">
            <select
                id="retailer"
                className="form-control"
                value={retailer}
                onChange={(e) => setRetailer(e.target.value)}
                style={{ backgroundColor: '#4e5a68', color: '#fff', minWidth: '180px' }}
            >
                <option value="all">Search All</option>
                {csvFiles.map((file, index) => (
                    <option key={index} value={file}>
                        {file}
                    </option>
                ))}
            </select>
            {retailer !== 'all' && (
                <FaTrash
                    onClick={() => handleDeleteFile(retailer)}
                    style={{ color: 'white', cursor: 'pointer', marginLeft: '10px' }}
                />
            )}
        </div>
    </div>

    {/* Upload CSV Section */}
    <div className="col-md-3" style={{ paddingRight: '20px' }}>
        <label>Upload CSV:</label>
        <input
            type="file"
            className="form-control"
            onChange={handleFileSelect}
            style={{ backgroundColor: '#4e5a68', color: '#fff' }}
        />
    </div>

    {/* Upload Button */}
    <div className="col-md-3 d-flex align-items-end">
        <button
            className="btn btn-primary mr-2"
            onClick={handleFileUpload}
            disabled={!selectedFile || uploading}
        >
            {uploading ? 'Uploading...' : 'Upload'}
        </button>
    </div>

    {/* Fetch Subscriptions Button and Stop/Resume */}
<div className="col-md-3 d-flex align-items-end">
    <button className="btn btn-primary mr-2" onClick={handleFetchClick}>
        Fetch Subscriptions
    </button>
    {eventSource && (
        <button 
            className="btn btn-danger" 
            onClick={handleStopClick}
            style={{ marginLeft: '8px' }}  // Adds explicit left margin
        >
            Stop
        </button>
    )}
    {isStopped && !eventSource && (
        <button 
            className="btn btn-success" 
            onClick={handleResumeClick}
            style={{ marginLeft: '8px' }}  // Adds explicit left margin
        >
            Resume
        </button>
    )}
</div>


</div>


            <div className="row">
                {Object.entries({
                    redeemed: { label: "Pending / Active", values: `${aggregation.redeemed.pending} / ${aggregation.redeemed.active}` },
                    status: { label: "Trialing / Paid", values: `${aggregation.status.trialing} / ${aggregation.status.paid}` },
                    timeframe: { label: "Monthly / Yearly", values: `${aggregation.timeframe.monthly} / ${aggregation.timeframe.yearly}` }
                }).map(([key, { label, values }]) => (
                    <div key={key} className="col-md-4 mb-4">
                        <div className="p-4 border rounded" style={{ backgroundColor: '#303940', height: collapsed[key] ? '120px' : 'auto', transition: 'height 0.3s' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="text-white mb-0">{label}</h5>
                                <button className="btn btn-link text-white" onClick={() => toggleCollapse(key)}>
                                    {collapsed[key] ? <FaChevronDown /> : <FaChevronUp />}
                                </button>
                            </div>
                            <div className="text-white" style={{ fontSize: '24px' }}>{values}</div>
                            {!collapsed[key] && <div style={{ transition: 'height 0.3s ease' }}><svg ref={graphRefs[key]} /></div>}
                        </div>
                    </div>
                ))}
            </div>

            <div className="d-flex justify-content-between align-items-center my-3">
                <div>
                    <label>Filter by Status: </label>
                    <select className="form-control d-inline-block w-auto ml-2" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                        <option value="all">All</option>
                        <option value="pending">Pending</option>
                        <option value="active">Active</option>
                        <option value="trialing">Trialing</option>
                        <option value="paid">Paid</option>
                    </select>
                </div>
                <div>
                    <label>Rows per page: </label>
                    <select className="form-control d-inline-block w-auto ml-2" value={rowsPerPage} onChange={(e) => setRowsPerPage(Number(e.target.value))}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                        <option value={1000}>1000</option>
                    </select>
                </div>
            </div>

            <table className="table table-dark table-striped mt-4">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Customer</th>
                        <th>Status</th>
                        <th>Period Start</th>
                        <th>Period End</th>
                        <th>Redeemed</th>
                        <th>Billing Timeframe</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedSubscriptions.map((subscription, index) => (
                        <tr key={index}>
                            <td>{subscription.id}</td>
                            <td>{subscription.customer}</td>
                            <td>{subscription.status}</td>
                            <td>{new Date(subscription.current_period_start * 1000).toLocaleDateString()}</td>
                            <td>{new Date(subscription.current_period_end * 1000).toLocaleDateString()}</td>
                            <td>{subscription.redeemed}</td>
                            <td>{subscription.current_billing_timeframe === '12' ? 'Yearly' : 'Monthly'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

    {filteredSubscriptions.length > 0 ? (
        <div className="d-flex justify-content-between align-items-center my-3">
            <button
                className="btn btn-light mr-2"
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
            >
                Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button
                className="btn btn-light ml-2"
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>
    ) : (
        <div className="my-3 text-center text-muted">No subscriptions available.</div>
    )}
        </div>
    );
};

export default StripeSubscriptions;
