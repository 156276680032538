import React, { useState } from 'react';
import { Container, Button, Card, Spinner, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DemoKeys = () => {
    const [licenseKey, setLicenseKey] = useState(null);
    const [loading, setLoading] = useState(false);

    const generateKey = async () => {
        setLoading(true);
        try {
            const response = await fetch('http://54.214.4.206:5004/stripe/generate-key', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to generate key');
            }

            const data = await response.json();
            setLicenseKey(data.license_key);
        } catch (error) {
            console.error('Error generating key:', error);
            alert('Error generating key');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container fluid className="min-vh-100" style={{ backgroundColor: '#242b33', color: '#fff', padding: '40px' }}>
            <Row>
                <Col md={6} lg={4}>
                    <h1 className="mb-4">Demo Key Generation</h1>
                    <p style={{ color: '#a8b3c2', fontSize: '1rem', marginBottom: '20px' }}>
                        Generate a 30 Day Free Trial License
                    </p>
                    <Button 
                        variant="primary" 
                        size="lg" 
                        onClick={generateKey} 
                        disabled={loading} 
                        className="w-100 mb-4"
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Generate Key'}
                    </Button>

                    {licenseKey && (
                        <Card 
                            className="p-3 text-center mt-3" 
                            style={{ backgroundColor: '#303940', color: '#fff', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}
                        >
                            <Card.Text className="mb-2" style={{ fontSize: '1rem', color: '#a8b3c2' }}>
                                Your License Key:
                            </Card.Text>
                            <h3 style={{ wordBreak: 'break-all', fontWeight: '600', fontSize: '1.4rem', color: '#e9ecef' }}>
                                {licenseKey}
                            </h3>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default DemoKeys;
