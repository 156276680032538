import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarComponent from './components/NavbarComponent';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import DemoKeys from './pages/DemoKeys';
import StripeSubscriptions from './pages/StripeSubscriptions';
import BundleKeys from './pages/BundleKeys';
import PendingApprovals from './pages/PendingApprovals';
import Settings from './pages/Settings';

const App = () => (
    <AuthProvider>
        <Router>
            <NavbarComponent />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<PrivateRoute><StripeSubscriptions /></PrivateRoute>} />
                <Route path="/demo-keys" element={<PrivateRoute><DemoKeys /></PrivateRoute>} />
                <Route path="/bundle-keys" element={<PrivateRoute><BundleKeys /></PrivateRoute>} />
                <Route path="/pending-approvals" element={<PrivateRoute><PendingApprovals /></PrivateRoute>} />
                <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            </Routes>
        </Router>
    </AuthProvider>
);

export default App;
