import React, { useState, useEffect } from 'react';
import { 
    fetchUsers, deleteUser, addUser, setUserRoleAndVisibility, 
    fetchEmailContent, updateEmailContent, fetchDocs, 
    uploadDoc, deleteDoc, fetchCcEmails, addCcEmail, removeCcEmail 
} from '../api/settingsApi';
import { Button, Modal, Form, Table, ListGroup, Spinner, DropdownButton, Dropdown } from 'react-bootstrap';

const Settings = () => {
    const [users, setUsers] = useState([]);
    const [emailSubject, setEmailSubject] = useState("");
    const [emailBody, setEmailBody] = useState("");
    const [docs, setDocs] = useState([]);
    const [ccEmails, setCcEmails] = useState([]);
    const [newCcEmail, setNewCcEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showDocModal, setShowDocModal] = useState(false);
    const [file, setFile] = useState(null);
    const [pageVisibility, setPageVisibility] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState({});
    const pages = ["Analytics", "Create Demo Keys", "Create Bundle Keys", "Pending Approvals", "Settings"];

    useEffect(() => {
        loadUsers();
        loadEmailContent();
        loadDocs();
        loadCcEmails();
    }, []);

    const loadUsers = async () => {
        setLoading(true);
        try {
            const data = await fetchUsers();
            setUsers(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error("Failed to fetch users:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddUser = async () => {
        try {
            await addUser(newEmail, newPassword);
            loadUsers();
            setShowUserModal(false);
            setNewEmail("");
            setNewPassword("");
        } catch (error) {
            console.error("Failed to add user:", error);
        }
    };

    const handleSetVisibility = async (email, role, visibility) => {
        try {
            await setUserRoleAndVisibility(email, role, visibility);
            loadUsers(); // Reload users to reflect changes
            setDropdownOpen(prev => ({ ...prev, [email]: false })); // Close dropdown after saving
        } catch (error) {
            console.error("Failed to update user visibility:", error);
        }
    };

    const handleVisibilityChange = (email, page) => {
        setPageVisibility(prevState => ({
            ...prevState,
            [email]: {
                ...(prevState[email] || {}),
                [page]: !(prevState[email] && prevState[email][page])
            }
        }));
    };

    const handleDeleteUser = async (email, index) => {
        if (index === 0) {
            alert("The first user cannot be deleted.");
            return;
        }
        if (window.confirm("Are you sure you want to delete this user?")) {
            try {
                await deleteUser(email);
                loadUsers();
            } catch (error) {
                console.error("Failed to delete user:", error);
            }
        }
    };

    const loadEmailContent = async () => {
        try {
            const content = await fetchEmailContent();
            setEmailSubject(content.subject || "");
            setEmailBody(content.body || "");
        } catch (error) {
            console.error("Failed to fetch email content:", error);
        }
    };

    const loadDocs = async () => {
        try {
            const { documents } = await fetchDocs();
            setDocs(Array.isArray(documents) ? documents : []);
        } catch (error) {
            console.error("Failed to fetch documents:", error);
        }
    };

    const loadCcEmails = async () => {
        try {
            const emails = await fetchCcEmails();
            setCcEmails(emails);
        } catch (error) {
            console.error("Failed to fetch CC emails:", error);
        }
    };

    const handleSaveEmailContent = async () => {
        try {
            await updateEmailContent(emailSubject, emailBody);
            alert("Email content updated successfully!");
        } catch (error) {
            console.error("Failed to update email content:", error);
        }
    };

    const handleUploadDoc = async () => {
        if (!file) return;
        try {
            await uploadDoc(file);
            loadDocs();
            setShowDocModal(false);
        } catch (error) {
            console.error("Failed to upload document:", error);
        }
    };

    const handleDeleteDoc = async (docName) => {
        if (window.confirm("Are you sure you want to delete this document?")) {
            try {
                await deleteDoc(docName);
                loadDocs();
            } catch (error) {
                console.error("Failed to delete document:", error);
            }
        }
    };

    const handleAddCcEmail = async () => {
        if (!newCcEmail) return;
        try {
            await addCcEmail(newCcEmail);
            loadCcEmails();
            setNewCcEmail("");
        } catch (error) {
            console.error("Failed to add CC email:", error);
        }
    };

    const handleRemoveCcEmail = async (email) => {
        if (window.confirm("Are you sure you want to remove this CC email?")) {
            try {
                await removeCcEmail(email);
                loadCcEmails();
            } catch (error) {
                console.error("Failed to remove CC email:", error);
            }
        }
    };

    return (
        <div className="container-fluid min-vh-100" style={{ backgroundColor: '#242b33', color: '#fff', padding: '40px' }}>
            <h1 className="mb-4">Settings</h1>

            {/* Manage Users Section */}
            <h3>Manage Users</h3>
            {loading ? (
                <Spinner animation="border" variant="light" />
            ) : (
                <ListGroup className="mb-3">
                    {users.map((user) => (
                        <ListGroup.Item key={user.email} className="bg-dark text-white d-flex justify-content-between align-items-center">
                            <span>{user.email} ({user.role})</span>
                            {user.role !== "Admin" && (
                                <DropdownButton
                                    title="Manage Access"
                                    variant="secondary"
                                    style={{ marginRight: "10px" }}
                                    show={dropdownOpen[user.email] || false}
                                    onClick={() => setDropdownOpen(prev => ({ ...prev, [user.email]: !prev[user.email] }))}
                                >
                                    {pages.map((page) => (
                                        <Dropdown.Item
                                            as="button"
                                            key={page}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleVisibilityChange(user.email, page);
                                            }}
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                label={page}
                                                checked={pageVisibility[user.email] && pageVisibility[user.email][page]}
                                                onChange={() => handleVisibilityChange(user.email, page)}
                                            />
                                        </Dropdown.Item>
                                    ))}
                                    <Dropdown.Divider />
                                    <Dropdown.Item as="button" onClick={() =>
                                        handleSetVisibility(user.email, user.role, pageVisibility[user.email])
                                    }>
                                        Save Visibility
                                    </Dropdown.Item>
                                </DropdownButton>
                            )}
                            <Button variant="danger" onClick={() => handleDeleteUser(user.email)}>Delete</Button>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
            <Button variant="primary" onClick={() => setShowUserModal(true)}>Add New User</Button>

            {/* Manage Email Content Section */}
            <h3 className="mt-5">Edit Email Content</h3>
            <Form.Group>
                <Form.Label>Subject</Form.Label>
                <Form.Control
                    type="text"
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                    placeholder="Email subject"
                    className="bg-dark text-white"
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Body</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={4}
                    value={emailBody}
                    onChange={(e) => setEmailBody(e.target.value)}
                    placeholder="Edit the email body here..."
                    className="bg-dark text-white"
                />
            </Form.Group>
            <Button variant="primary" onClick={handleSaveEmailContent}>Save Email Content</Button>

            {/* HTML Preview */}
            <h5 className="mt-4">HTML Preview</h5>
            <div className="bg-light text-dark p-3" style={{ whiteSpace: "pre-wrap", border: "1px solid #ddd" }}
                dangerouslySetInnerHTML={{ __html: emailBody }}
            />

            {/* Manage CC Emails Section */}
            <h3 className="mt-5">Manage CC Emails</h3>
            <Table striped bordered hover variant="dark" className="mb-3">
                <thead>
                    <tr>
                        <th>CC Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {ccEmails.map((email) => (
                        <tr key={email}>
                            <td>{email}</td>
                            <td>
                                <Button variant="danger" onClick={() => handleRemoveCcEmail(email)}>
                                    Remove
                                </Button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <Form.Control
                                type="email"
                                value={newCcEmail}
                                onChange={(e) => setNewCcEmail(e.target.value)}
                                placeholder="Add CC email"
                                className="bg-dark text-white"
                            />
                        </td>
                        <td>
                            <Button variant="primary" onClick={handleAddCcEmail}>Add</Button>
                        </td>
                    </tr>
                </tbody>
            </Table>

            {/* Manage Document Repository Section */}
            <h3 className="mt-5">Manage Document Repository</h3>
            <Table striped bordered hover variant="dark" className="mb-3">
                <thead>
                    <tr>
                        <th>Document Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {docs.map(doc => (
                        <tr key={doc}>
                            <td>{doc}</td>
                            <td>
                                <Button variant="danger" onClick={() => handleDeleteDoc(doc)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button variant="primary" onClick={() => setShowDocModal(true)}>Upload New Document</Button>

            {/* User Modal */}
            <Modal show={showUserModal} onHide={() => setShowUserModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            className="bg-dark text-white"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="bg-dark text-white"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUserModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleAddUser}>Save</Button>
                </Modal.Footer>
            </Modal>

            {/* Document Upload Modal */}
            <Modal show={showDocModal} onHide={() => setShowDocModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Choose Document File</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                            className="bg-dark text-white"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDocModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleUploadDoc}>Upload</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Settings;
