const BASE_URL = 'http://54.214.4.206:5004/api'; // Updated with /api here for consistency

export const fetchPackageConfigs = async () => {
    const response = await fetch(`${BASE_URL}/packages`);
    if (!response.ok) throw new Error('Failed to fetch package configs');
    return response.json();
};

export const fetchBundleOptions = async () => {
    const response = await fetch(`${BASE_URL}/bundle-options`);
    if (!response.ok) throw new Error('Failed to fetch bundle options');
    return response.json();
};

export const createPackageConfig = async (packageData) => {
    const response = await fetch(`${BASE_URL}/packages`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(packageData),
    });
    if (!response.ok) throw new Error('Failed to create package config');
    return response.json();
};

export const generateKeys = async (packageId, totalSize, csvName) => {
    const response = await fetch(`${BASE_URL}/generate-keys?package_id=${packageId}&total_size=${totalSize}&csv_name=${csvName}`, {
        method: 'POST',
    });
    if (!response.ok) throw new Error('Failed to generate keys');
    return response.json();
};
export const deleteRequest = async (approvalId) => {
    const response = await fetch(`${BASE_URL}/delete-request/${approvalId}`, {
        method: 'DELETE',
    });
    if (!response.ok) throw new Error('Failed to delete request');
    return response.json();
};

export const deletePackageConfig = async (packageId) => {
    const response = await fetch(`${BASE_URL}/packages/${packageId}`, {
        method: 'DELETE',
    });
    if (!response.ok) throw new Error('Failed to delete package');
    return response.json();
};

export const fetchAllApprovals = async () => {
    const response = await fetch(`${BASE_URL}/all-approvals`);
    if (!response.ok) throw new Error('Failed to fetch approvals');
    return response.json();
};

export const approveRequest = async (approvalId) => {
    const response = await fetch(`${BASE_URL}/approve-request/${approvalId}`, {
        method: 'POST',
    });
    if (!response.ok) throw new Error('Failed to approve request');
    return response.json();
};

export const denyRequest = async (approvalId) => {
    const response = await fetch(`${BASE_URL}/deny-request/${approvalId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    });
    if (!response.ok) {
        throw new Error('Failed to disapprove request');
    }
    return response.json();
};


export const requestApproval = async (approvalData) => {
    const response = await fetch(`${BASE_URL}/request-approval`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(approvalData),
    });
    if (!response.ok) throw new Error('Failed to submit approval request');
    return response.json();
};
