import React, { useState, useEffect } from 'react';
import { fetchPackageConfigs, fetchBundleOptions, createPackageConfig, generateKeys, deletePackageConfig } from '../api/packageApi';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const BundleKeys = () => {
    const [packageConfigs, setPackageConfigs] = useState([]);
    const [bundleOptions, setBundleOptions] = useState([]);
    const [priceMapping, setPriceMapping] = useState({});
    const [formValues, setFormValues] = useState({
        packageId: "",
        bundleId: "SMB",
        vendor: "",
        keyAmount: "",
        trialMonths: ""
    });
    const [showModal, setShowModal] = useState(false);
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const [csvName, setCsvName] = useState("");
    const [keyCount, setKeyCount] = useState("");
    const [showAllPackages, setShowAllPackages] = useState(false);
    const [loading, setLoading] = useState(false);
    const [generatedCsvUrl, setGeneratedCsvUrl] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const packageData = await fetchPackageConfigs();
            setPackageConfigs(packageData);

            const bundleData = await fetchBundleOptions();
            setBundleOptions(bundleData.bundle_options);
            setPriceMapping(bundleData.price_mapping);

            if (packageData.length > 0) {
                const maxPackageId = Math.max(...packageData.map(pkg => pkg.package_id)) + 1;
                setFormValues(prevValues => ({ ...prevValues, packageId: maxPackageId }));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleGenerateKeysClick = (packageId) => {
        setSelectedPackageId(packageId);
        setShowModal(true);
    };

    const handleGenerateKeys = async () => {
        if (!csvName || !keyCount || isNaN(keyCount)) return alert("Please provide a valid CSV name and key count.");
        
        setLoading(true);
        try {
            await generateKeys(selectedPackageId, Number(keyCount), csvName);
            alert('Keys generated successfully!');
            
            // Delay setting URL to ensure file is ready on the server
            setTimeout(() => {
                setGeneratedCsvUrl(`${window.location.origin}/api/download-csv/${csvName}`);
            }, 1000);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || 'Failed to generate keys';
            alert(errorMessage.includes("already exists") ? "CSV file with this name already exists." : errorMessage);
        } finally {
            setLoading(false);
        }
    };
    const handleDownloadCsv = async () => {
        // if (generatedCsvUrl) {
        //     const link = document.createElement('a');
        //     link.href = generatedCsvUrl; // Full URL for download
        //     link.download = `${csvName}.csv`; // Ensure single .csv extension
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //     setShowModal(false); // Close modal after download
        // }
        const response = await fetch(`http://54.214.4.206:5004/csv/download/${csvName}.csv`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            console.error('Failed to download file:', response.status);
            return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = csvName; // specify the file name
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };
    const handleDeletePackage = async (packageId) => {
        if (!window.confirm("Are you sure you want to delete this package?")) return;

        setLoading(true);
        try {
            await deletePackageConfig(packageId);
            alert('Package deleted successfully!');
            fetchData();
        } catch (error) {
            console.error('Error deleting package:', error);
            alert('Failed to delete package');
        } finally {
            setLoading(false);
        }
    };



    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value || "" }));  // Avoid null values
    };

    const handleCreatePackage = async () => {
        if (!formValues.vendor.match(/^\S+$/)) {
            alert("Vendor name should be a single word with no spaces.");
            return;
        }

        setLoading(true);
        try {
            await createPackageConfig({
                packageId: formValues.packageId,
                bundleId: formValues.bundleId,
                vendor: formValues.vendor,
                priceId: priceMapping[formValues.bundleId],
                keyAmount: Number(formValues.keyAmount),
                trialMonths: Number(formValues.trialMonths)
            });
            alert('Package created successfully!');
            fetchData();
            setFormValues(prevValues => ({
                ...prevValues,
                packageId: prevValues.packageId + 1,
                vendor: '',
                keyAmount: '',
                trialMonths: ''
            }));
        } catch (error) {
            console.error('Error creating package:', error);
            alert('Failed to create package');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-fluid min-vh-100" style={{ backgroundColor: '#242b33', color: '#fff', padding: '40px' }}>
            <h1 className="mb-4">Bundle Keys</h1>

            {/* Create Package Form */}
            <div className="row mb-4">
                <div className="col-md-6">
                    <h3>Create New Package</h3>
                    <div className="form-group">
                        <label>Package ID</label>
                        <input type="text" className="form-control" value={formValues.packageId || ""} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Bundle ID</label>
                        <select
                            name="bundleId"
                            className="form-control"
                            value={formValues.bundleId || ""}
                            onChange={handleFormChange}
                        >
                            {bundleOptions.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Vendor</label>
                        <input
                            type="text"
                            name="vendor"
                            className="form-control"
                            value={formValues.vendor || ""}
                            onChange={handleFormChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Key Amount</label>
                        <input
                            type="number"
                            name="keyAmount"
                            className="form-control"
                            value={formValues.keyAmount || ""}
                            onChange={handleFormChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Trial Months</label>
                        <input
                            type="number"
                            name="trialMonths"
                            className="form-control"
                            value={formValues.trialMonths || ""}
                            onChange={handleFormChange}
                        />
                    </div>
                    <Button variant="primary" onClick={handleCreatePackage} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : "Create Package"}
                    </Button>
                </div>
            </div>

            {/* Show All Packages and Manage */}
            <div className="row mb-4">
                <div className="col-12">
                    <button className="btn btn-primary mb-3" onClick={() => setShowAllPackages(!showAllPackages)}>
                        {showAllPackages ? "Hide Packages" : "Show All Packages"}
                    </button>
                    {showAllPackages && (
                        <ul className="list-group">
                            {packageConfigs.map((pkg) => (
                                <li key={pkg.id} className="list-group-item bg-dark text-white d-flex justify-content-between align-items-center">
                                    <div>
                                        ID: {pkg.id}, Package ID: {pkg.package_id}, Bundle: {pkg.bundle_id}, 
                                        Vendor: {pkg.vendor}, Key Amount: {pkg.key_amount}, Trial Months: {pkg.trial_months}
                                    </div>
                                    <div>
                                        <button
                                            className="btn btn-success btn-sm ml-3"
                                            onClick={() => handleGenerateKeysClick(pkg.package_id)}
                                        >
                                            Generate
                                        </button>
                                        <button
                                            className="btn btn-danger btn-sm ml-2"
                                            onClick={() => handleDeletePackage(pkg.package_id)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            {/* Modal for Key Generation */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Generate Keys</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label>CSV Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={csvName}
                                onChange={(e) => setCsvName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Key Count</label>
                            <input
                                type="number"
                                className="form-control"
                                value={keyCount}
                                onChange={(e) => setKeyCount(e.target.value)}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleGenerateKeys} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : "Generate"}
                    </Button>
                    {generatedCsvUrl && (
                        <Button variant="success" onClick={handleDownloadCsv}>Download CSV</Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BundleKeys;
